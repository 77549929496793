<template>
  <div class="page">
    <div class="rule">
      <div class="rule-module">
        <div class="rule-module-title">1.积分兑换规则</div>
        <div class="rule-module-desc">
          1元可兑换100积分，用户单次至少需要兑换1000积分，且兑换积分需为1000的整数倍；
        </div>
        <div class="rule-module-desc">
          一旦现金兑换为积分，该行为不可进行撤销；
        </div>
        <div class="rule-module-desc">
          单用户每日单笔最多可兑换200000积分，单月最多可兑入5000000积分；
        </div>
      </div>
      <div class="rule-module">
        <div class="rule-module-title">2.积分有效期</div>
        <div class="rule-module-desc">永久积分：用户在本平台兑换或通过活动奖励获得的积分均长期有效；</div>
      </div>
      <div class="rule-module">
        <div class="rule-module-title">3.积分使用</div>
        <div class="rule-module-desc">积分可在本平台兑换各类超值商品，具体商品介绍详见商品详情页。积分一经兑换使用，不得退回；</div>
      </div>
      <div class="rule-module">
        <div class="rule-module-title">4.积分不可转让，不可出售</div>
      </div>
      <div class="rule-module">
        <div class="rule-module-title">5.积分客服</div>
        <div class="rule-module-desc">如有关于积分或积分所兑商品的相关疑问，请咨询官方客服4006655507 [每日9:00--18:00]</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'QinghuaRule',
  mounted() {
    document.title = '积分兑换规则';
  },
};
</script>
<style lang="scss" scoped>
.page {
  font-family: PingFangSC-Regular, PingFang SC;
  width: 750px;
  min-height: 100%;
  background: #FFFFFF;
  margin: auto;
}
.rule {
  padding-top: 20px;
  margin: 0 32px;
  &-module {
    margin-bottom: 40px;
    &-title {
      font-size: 32px;
      font-weight: 600;
      color: #24242A;
      line-height: 44px;
    }
    &-desc {
      font-size: 28px;
      font-weight: 400;
      color: #24242A;
      line-height: 40px;
      margin-top: 20px;
    }
  }
}
</style>
